import SystemToast from '@client/components/SystemToast';
import { raiseToast } from '@client/components/Toaster';
import { handleCustomError } from '@client/utils/errors';
import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface NotificationRequest {
  uuid: string;
  requestorUUID: string;
  requestor?: { firstName: string; lastName: string };
  type: string;
  data: string;
}

export const sliceId = 'bundleGeneratorNotifications';

export const fetchGeneratorNotificationRequests = createAsyncThunk(`${sliceId}/fetch`, async () => {
  const url = api('/bundle/generator/notification-request');
  try {
    const response = (await request(url)) as { data: NotificationRequest[] };
    return response.data;
  } catch (err) {
    raiseToast(
      <SystemToast
        type={SystemToast.Type.ERROR}
        message={handleCustomError(err, 'Unable to subscribe to generator notifications')}
      />
    );
    throw err;
  }
});

export const createGeneratorNotificationRequests = createAsyncThunk(
  `${sliceId}/create`,
  async (notificationRequests: { type: string; data: string }[], thunkAPI) => {
    const url = api('/bundle/generator/notification-request');
    try {
      const body = JSON.stringify({ data: notificationRequests });
      const options = { method: 'POST', body };
      await request(url, options);
      thunkAPI.dispatch(fetchGeneratorNotificationRequests());
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      thunkAPI.dispatch(resetForm());
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, 'Unable to subscribe to generator notifications')}
        />
      );
    }
  }
);

export const deleteGeneratorNotificationRequests = createAsyncThunk(
  `${sliceId}/delete`,
  async (requestUuid: string) => {
    const url = api(`/bundle/generator/notification-request/${requestUuid}`);
    const options = { method: 'DELETE' };
    try {
      if (requestUuid) {
        await request(url, options);
      }
      return requestUuid;
    } catch (err) {
      raiseToast(
        <SystemToast
          type={SystemToast.Type.ERROR}
          message={handleCustomError(err, 'Unable to delete notification request subscription')}
        />
      );
    }
  }
);

interface GeneratorNotificationsState {
  fetchLoading: boolean;
  createLoading: boolean;
  deleteLoading: boolean;
  notificationRequests: NotificationRequest[];
  rawLegNumbers: string;
  filters: any;
  sorting: any;
}

const getInitialState = (): GeneratorNotificationsState => ({
  fetchLoading: false,
  createLoading: false,
  deleteLoading: false,
  notificationRequests: [],
  rawLegNumbers: '',
  filters: {},
  sorting: {
    field: 'expireAt',
    direction: 'ASC'
  }
});

export const slice = createSlice({
  name: sliceId,
  initialState: getInitialState(),
  reducers: {
    resetForm: (state) => {
      state.rawLegNumbers = '';
    },
    updateInputValue: (state, action) => {
      state.rawLegNumbers = action.payload;
    },
    updateSort: (state, action) => {
      if (action.payload === state.sorting.field) {
        state.sorting.direction = state.sorting.direction === 'ASC' ? 'DESC' : 'ASC';
      } else {
        state.sorting = {
          field: action.payload,
          direction: 'ASC'
        };
      }
    }
  },
  extraReducers: (builder) => {
    // Fetch
    builder.addCase(fetchGeneratorNotificationRequests.pending, (state) => {
      state.fetchLoading = true;
    });
    builder.addCase(fetchGeneratorNotificationRequests.rejected, (state) => {
      state.fetchLoading = false;
    });
    builder.addCase(fetchGeneratorNotificationRequests.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.notificationRequests = action.payload;
    });

    // Create (post)
    builder.addCase(createGeneratorNotificationRequests.pending, (state) => {
      state.createLoading = true;
    });
    builder.addCase(createGeneratorNotificationRequests.rejected, (state) => {
      state.createLoading = false;
    });
    builder.addCase(createGeneratorNotificationRequests.fulfilled, (state) => {
      state.createLoading = false;
    });

    // Delete (post)
    builder.addCase(deleteGeneratorNotificationRequests.pending, (state) => {
      state.deleteLoading = true;
    });
    builder.addCase(deleteGeneratorNotificationRequests.rejected, (state) => {
      state.deleteLoading = false;
    });
    builder.addCase(deleteGeneratorNotificationRequests.fulfilled, (state, action) => {
      state.deleteLoading = false;
      if (action.payload) {
        const deletedRequestIndex = state.notificationRequests.findIndex(
          (notificationRequest) => notificationRequest.uuid === action.payload
        );
        state.notificationRequests.splice(deletedRequestIndex, 1);
      }
    });
  }
});

export const { resetForm, updateInputValue, updateSort } = slice.actions;

export default slice;
