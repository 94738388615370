import React, { ReactElement } from 'react';
import CopyButton from '@client/components/CopyButton/component';
import './style.css';

interface Props {
  containerNumbers: string[];
}

function ContainerNumbers({ containerNumbers }: Props): ReactElement {
  return (
    <p className="cancellable-containers">
      <b>Container numbers:</b> {containerNumbers.join(', ')}
      <CopyButton value={containerNumbers.join(' ')} />
    </p>
  );
}

export default React.memo(ContainerNumbers);
