// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bulk-order-delete-form {
  display: grid;
  position: relative;
  row-gap: 30px;
  width: 35vw;
  max-width: 800px;
}

  .bulk-order-delete-form__status-text {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0;
  }

  .bulk-order-delete-form__cancellable-wrapper {
    display: grid;
    grid-gap: 5px;
    margin-top: 25px;
    max-width: 875px;
    width: 90%;
  }

  .bulk-order-delete-form__button {
    width: 50%;
    min-width: calc(315px - 50%);
  }

  .bulk-order-delete-form__modal {
    position: fixed !important;
  }

  .bulk-order-delete-form__modal-content {
    display: flex;
    flex-direction: column;
    background: #fff;
    row-gap: 15px;
    margin: auto;
    width: 75vw;
    height: 75vh;
    justify-content: center;
    align-items: center;
  }

  .bulk-order-delete-form__modal-dialogue {
    font-size: 1.35rem;
    text-align: center;
    font-weight: 600;
    margin-top: 0;
  }

  .bulk-order-delete-form__modal-dialogue--p {
      margin: 0;
    }

  .bulk-order-delete-form__btn-group {
    display: flex;
    justify-content: center;
    column-gap: 15px;
  }

  @media only screen and (width <= 575px) {

  .bulk-order-delete-form__btn-group {
      flex-wrap: wrap;
      row-gap: 15px
  }
    }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BulkOrderCancellation/features/Form/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,gBAAgB;AA0DlB;;EAxDE;IACE,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,aAAa;IACb,gBAAgB;IAChB,gBAAgB;IAChB,UAAU;EACZ;;EAEA;IACE,UAAU;IACV,4BAA4B;EAC9B;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,aAAa;IACb,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;EAKf;;EAHE;MACE,SAAS;IACX;;EAGF;IACE,aAAa;IACb,uBAAuB;IACvB,gBAAgB;EAMlB;;EAJE;;EALF;MAMI,eAAe;MACf;EAEJ;IADE","sourcesContent":[".bulk-order-delete-form {\n  display: grid;\n  position: relative;\n  row-gap: 30px;\n  width: 35vw;\n  max-width: 800px;\n\n  &__status-text {\n    font-size: 1.2rem;\n    font-weight: 600;\n    margin-bottom: 0;\n  }\n\n  &__cancellable-wrapper {\n    display: grid;\n    grid-gap: 5px;\n    margin-top: 25px;\n    max-width: 875px;\n    width: 90%;\n  }\n\n  &__button {\n    width: 50%;\n    min-width: calc(315px - 50%);\n  }\n\n  &__modal {\n    position: fixed !important;\n  }\n\n  &__modal-content {\n    display: flex;\n    flex-direction: column;\n    background: #fff;\n    row-gap: 15px;\n    margin: auto;\n    width: 75vw;\n    height: 75vh;\n    justify-content: center;\n    align-items: center;\n  }\n\n  &__modal-dialogue {\n    font-size: 1.35rem;\n    text-align: center;\n    font-weight: 600;\n    margin-top: 0;\n\n    &--p {\n      margin: 0;\n    }\n  }\n\n  &__btn-group {\n    display: flex;\n    justify-content: center;\n    column-gap: 15px;\n\n    @media only screen and (width <= 575px) {\n      flex-wrap: wrap;\n      row-gap: 15px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
