// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cancellable-containers {
  display: flex;
  column-gap: 5px;
  padding: 15px;
  margin-top: 0;
  border: 1px solid var(--color-grayscale-5);
}
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BulkOrderCancellation/features/ContainerNumbers/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,aAAa;EACb,aAAa;EACb,0CAA0C;AAC5C","sourcesContent":[".cancellable-containers {\n  display: flex;\n  column-gap: 5px;\n  padding: 15px;\n  margin-top: 0;\n  border: 1px solid var(--color-grayscale-5);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
