import { memo } from 'react';
import { Helmet } from 'react-helmet';
import Component from './component';
import { BulkOperationType } from './store/types/BulkOperationTypes';

interface ContainerProps {
  title: string;
  operationType: BulkOperationType;
  isEnabled: boolean;
}

function Container({ title, operationType, isEnabled }: ContainerProps) {
  return (
    <>
      <Helmet title={title} />
      <Component title={title} operationType={operationType} isEnabled={isEnabled} />
    </>
  );
}

export default memo(Container);
