import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@client/reduxProvider';
import { getAuthToken } from '@client/utils/auth';
import { api } from '@client/utils/url';
import request from '@client/utils/request';
import { raiseToast } from '@client/components/Toaster';
import SystemToast from '@client/components/SystemToast';
import { BulkHistory } from './types/BulkHistory.entity';
import { BULK_OPERATION_TYPES, BULK_OPERATION_TYPES_TO_UPLOAD, BulkOperationType } from './types/BulkOperationTypes';

export const bulkOperationUpdatesAdapter = createEntityAdapter<BulkHistory>({
  selectId: (item) => item.uuid
});

export const selectBulkOperationUpdates = (state: RootState) => state.admin.bulkOperationUpdates;

export const fetchBulkOperationUpdateHistoryByType = createAsyncThunk<
  BulkHistory[],
  { operationType: BulkOperationType; triggerLoadingState?: boolean },
  { state: RootState }
>(
  'admin/bulkOperationUpdates/fetchBulkOperationUpdateHistoryByType',
  async ({ operationType }, thunkApi) => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    const url = api(`/bulk-history?operation=${operationType}`, '/bulk-operation');

    try {
      const { data } = await request(url, options);

      return data;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch Bulk Operations" />);
      thunkApi.abort();
    }
  },
  {
    condition: ({ operationType }, { getState }) => {
      const { loadingByOperationType } = selectBulkOperationUpdates(getState());

      if (loadingByOperationType[operationType]) {
        return false;
      }
    }
  }
);

export const fetchBulkOperationUpdateHistoryDetail = createAsyncThunk<BulkHistory, string, { state: RootState }>(
  'admin/bulkOperationUpdates/fetchBulkOperationUpdateHistoryDetail',
  async (uuid: string, thunkApi) => {
    const authHeader = getAuthToken();

    const options: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authHeader}`
      },
      credentials: 'same-origin'
    };

    const url = api(`/bulk-history/${uuid}`, '/bulk-operation');

    try {
      const data = await request(url, options);

      return data;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to fetch Bulk Operations Details" />);
      thunkApi.abort();
    }
  },
  {
    condition: (uuid, { getState }) => {
      const { loadingDetailByUuid } = selectBulkOperationUpdates(getState());

      if (loadingDetailByUuid[uuid]) {
        return false;
      }
    }
  }
);

export const uploadBulkOperationFile = createAsyncThunk<
  void,
  { operationType: BulkOperationType; formData: FormData },
  { state: RootState }
>('admin/bulkOperationUpdates/uploadBulkOperationFile', async ({ operationType, formData }, thunkApi) => {
  const authHeader = getAuthToken();

  const options: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${authHeader}`
    },
    credentials: 'same-origin',
    body: formData
  };

  const url = api(`/bulk-operations/${BULK_OPERATION_TYPES_TO_UPLOAD[operationType]}`, '/bulk-operation');

  try {
    await request(url, options);

    raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="Successfully uploaded" />);
  } catch (err) {
    raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Failed uploading" />);

    return thunkApi.rejectWithValue(err);
  }
});

const bulkOperationUpdatesStore = createSlice({
  name: 'admin/bulkOperationUpdates',
  initialState: bulkOperationUpdatesAdapter.getInitialState<{
    loadingByOperationType: Record<BulkOperationType, boolean>;
    loadingDetailByUuid: Record<string, boolean>;
    operationHistoryByType: Record<BulkOperationType, BulkHistory[]>;
  }>({
    loadingByOperationType: {
      [BULK_OPERATION_TYPES.FSC_UPDATE]: false,
      [BULK_OPERATION_TYPES.LOAD_ID_UPDATE]: false,
      [BULK_OPERATION_TYPES.INVOICE_PDF_GENERATION]: false,
      [BULK_OPERATION_TYPES.ORDER_STATUS_UPDATE]: false
    },
    loadingDetailByUuid: {},
    operationHistoryByType: {
      [BULK_OPERATION_TYPES.FSC_UPDATE]: [],
      [BULK_OPERATION_TYPES.LOAD_ID_UPDATE]: [],
      [BULK_OPERATION_TYPES.INVOICE_PDF_GENERATION]: [],
      [BULK_OPERATION_TYPES.ORDER_STATUS_UPDATE]: []
    }
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBulkOperationUpdateHistoryByType.pending, (state, action) => {
      const { operationType } = action.meta.arg;
      state.loadingByOperationType[operationType] = true;
    });
    builder.addCase(fetchBulkOperationUpdateHistoryByType.rejected, (state, action) => {
      const { operationType } = action.meta.arg;
      state.loadingByOperationType[operationType] = false;
    });
    builder.addCase(fetchBulkOperationUpdateHistoryByType.fulfilled, (state, action) => {
      const { operationType } = action.meta.arg;
      state.loadingByOperationType[operationType] = false;
      state.operationHistoryByType[operationType] = action.payload;
    });
    builder.addCase(fetchBulkOperationUpdateHistoryDetail.pending, (state, action) => {
      state.loadingDetailByUuid[action.meta.arg] = true;
    });
    builder.addCase(fetchBulkOperationUpdateHistoryDetail.rejected, (state, action) => {
      delete state.loadingDetailByUuid[action.meta.arg];
    });
    builder.addCase(fetchBulkOperationUpdateHistoryDetail.fulfilled, (state, action) => {
      delete state.loadingDetailByUuid[action.meta.arg];

      const detail = action.payload;
      const detailOperationType = detail.operationType;

      const index = state.operationHistoryByType[detailOperationType].findIndex((item) => item.uuid === detail.uuid);

      if (index === -1) {
        state.operationHistoryByType[detailOperationType].push(detail);
      } else {
        state.operationHistoryByType[detailOperationType][index] = detail;
      }
    });
  }
});

export const selectBulkOperationUpdateLoadingByType = createSelector(
  selectBulkOperationUpdates,
  (state) => state.loadingByOperationType
);

export const selectBulkOperationUpdatesHistoryByType = createSelector(
  selectBulkOperationUpdates,
  (state) => state.operationHistoryByType
);

export const selectBulkOperationUpdateLoadingForType = (operationType: BulkOperationType) =>
  createSelector(selectBulkOperationUpdateLoadingByType, (updatesLoadingByType) => updatesLoadingByType[operationType]);

export const selectBulkOperationUpdatesHistoryForType = (operationType: BulkOperationType) =>
  createSelector(
    selectBulkOperationUpdatesHistoryByType,
    (operationUpdatesByType) => operationUpdatesByType[operationType]
  );

export const selectBulkOperationUpdatesDetailLoading = (uuid: string) =>
  createSelector(selectBulkOperationUpdates, (state) => state.loadingDetailByUuid[uuid]);

export default bulkOperationUpdatesStore;
