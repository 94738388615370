import { useMemo } from 'react';
import {
  BulkOperationItem,
  FscUpdateValueItem,
  InvoicePdfGenerationValueItem,
  LoadIdUpdateValueItem,
  OrderStatusUpdateValueItem
} from '../../store/types/BulkOperationItem.entity';
import { BULK_OPERATION_TYPES, BulkOperationType } from '../../store/types/BulkOperationTypes';

import './style.css';

interface BulkHistorySubRowProps {
  operationType: BulkOperationType;
  item: BulkOperationItem;
}

const baseClassName = 'BulkHistorySubRow';

const VALUE_MAPPERS_FOR_OPERATION_TYPE: Record<BulkOperationType, (value: any, index: number) => JSX.Element> = {
  [BULK_OPERATION_TYPES.FSC_UPDATE]: (value: FscUpdateValueItem) => (
    <div className={`${baseClassName}__values-item`} key={value.lineItemUuid}>
      <div>LineItem Type: {value.lineItemType}</div>
      <div>Unit Price: {value.unitPrice}</div>
      <div>Total Quantity: {value.totalQuantity}</div>
    </div>
  ),
  [BULK_OPERATION_TYPES.LOAD_ID_UPDATE]: (value: LoadIdUpdateValueItem, index) => (
    <div
      className={`${baseClassName}__values-item`}
      key={`${value.customerPurchaseOrder ?? 'N/A'}-${value.customerTransportOrder ?? 'N/A'}-${index}`}
    >
      <div>Customer Purchase Order: {value.customerPurchaseOrder ?? 'N/A'}</div>
      <div>Customer Transport Order: {value.customerTransportOrder ?? 'N/A'}</div>
    </div>
  ),
  [BULK_OPERATION_TYPES.INVOICE_PDF_GENERATION]: (value: InvoicePdfGenerationValueItem, index) => (
    <div className={`${baseClassName}__values-item`} key={`${value.orderNumber}-${index}`}>
      <div>Order Number: {value.orderNumber}</div>
    </div>
  ),
  [BULK_OPERATION_TYPES.ORDER_STATUS_UPDATE]: (value: OrderStatusUpdateValueItem, index) => (
    <div className={`${baseClassName}__values-item`} key={`${value.orderNumber}-${index}`}>
      <div>Order Number: {value.orderNumber}</div>
      <div>Order Status: {value.status}</div>
    </div>
  )
};

function BulkHistorySubRow(props: BulkHistorySubRowProps) {
  const statusClass = useMemo(() => {
    switch (props.item.operationStatus) {
      case 'completed':
        return `${baseClassName}-completed`;
      case 'failed':
        return `${baseClassName}-failed`;
      case 'running':
        return `${baseClassName}-running`;
      case 'submitted':
      default:
        return `${baseClassName}-submitted`;
    }
  }, [props.item]);

  return (
    <div className={`${baseClassName} ${statusClass}`}>
      <div>Status: {props.item.operationStatus}</div>
      <div>Order Number: {props.item.values[0].orderNumber}</div>
      <div>Reason: {props.item.reason || 'N/A'}</div>
      <div>Updates:</div>
      <div className={`${baseClassName}__values-container`}>
        {props.item.values.map(VALUE_MAPPERS_FOR_OPERATION_TYPE[props.operationType])}
      </div>
    </div>
  );
}

export default BulkHistorySubRow;
