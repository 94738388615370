import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import Component from './component';
import { createGeneratorNotificationRequests, fetchGeneratorNotificationRequests } from './store';
import {
  selectGeneratorNotificationsSlice,
  selectSortedGeneratorNotificationRequests,
  selectSorting
} from './store/selectors';

function Container() {
  const dispatch = useAppDispatch();
  const state = useSelector(selectGeneratorNotificationsSlice);
  const notificationRequests = useSelector(selectSortedGeneratorNotificationRequests);
  const sort = useSelector(selectSorting);

  const getData = () => {
    dispatch(fetchGeneratorNotificationRequests());
  };

  useEffect(() => {
    getData();
  }, []);

  const createNotificationRequests = (requests: { type: string; data: string }[]) => {
    dispatch(createGeneratorNotificationRequests(requests));
  };

  return (
    <>
      <Helmet title="Bundle Notification" />
      <Component
        onCreate={createNotificationRequests}
        data={notificationRequests}
        sort={sort}
        inputValue={state.rawLegNumbers}
      />
    </>
  );
}

export default React.memo(Container);
