import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import request from '@client/utils/request';
import { api } from '@client/utils/url';
import Stop from '@client/_blessed/store/entities/stops/model';
import { selectAdminSlice } from '../../../store/selectors';
import { sortByCreationStatus } from './helpers/sortByCreationStatus';

const TBD_DEPARTMENT_UUID = '7b1d3e6d-37b9-ce73-2245-5994a51cabf5';

export interface GeneratorBundle {
  bundleId: string;
  legNumbers: number[];
  creationStatus: 'created' | 'rejected' | 'pending' | 'invalidated';
  feedback: string | null;
  type: string;
}

export interface BundleGeneratorMatchInfo {
  legNumber: number;
  legUuid: string;
  steamShippingLineAccountUuid: string;
  status: string;
  orderNumber: string;
  containerSize: string;
  containerNumber: string;
  importExport: 'import' | 'export';
  deliveryType: string;
  carrierUuid: string;
  driverUuid: string;
  truckUuid: string;
  visibility: string;
  emptyReadyAt: string;
  lastFreeDay: string;
  perDiemDueDate: string;
  chassisNumber: string;
  chassisPool?: {
    abbreviation: string;
    badgeColor: string;
  };
  bookingBL: string;
  bundleUuid: string;

  stops: Stop[];
}

export type BundleGeneratorMatch = [BundleGeneratorMatchInfo, BundleGeneratorMatchInfo];

interface FetchBundleGeneatorSchemeResponse {
  bundles: GeneratorBundle[];
  legInfo: Record<number, BundleGeneratorMatchInfo>;
}

export const fetchProspectiveBundles = createAsyncThunk(
  'fetchBundleGeneratorScheme',
  async (params: { legNumbers?: number[] }, thunkAPI) => {
    try {
      let url = api(`/bundle/generator/prospective-bundles?creationStatus=pending,created,rejected`);

      if (params.legNumbers) {
        url += `&legNumbers=${params.legNumbers.join(',')}`;
      }

      const response = await request<FetchBundleGeneatorSchemeResponse>(url);

      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateProspectiveBundleAsCreated = createAsyncThunk(
  'updateProspectiveBundleAsCreated',
  async (params: { bundleIds: string[] }, thunkAPI) => {
    try {
      const url = api(`/bundle/generator/prospective-bundles/create`);

      await request<void>(url, {
        method: 'POST',
        body: JSON.stringify({
          bundleIds: params.bundleIds
        })
      });
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updatedProspectiveBundleAsRejected = createAsyncThunk(
  'updateProspectiveBundleAsRejected',
  async (params: { bundles: Array<{ bundleId: string; feedback: string }> }, thunkAPI) => {
    try {
      const url = api(`/bundle/generator/prospective-bundles/reject`);

      await request<void>(url, {
        method: 'POST',
        body: JSON.stringify({
          bundles: params.bundles
        })
      });
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

interface BundleGeneratorState {
  loading: boolean;
  bundles: GeneratorBundle[];
  legInfo: Record<number, BundleGeneratorMatchInfo>;
}

const getInitialState = (state: Partial<BundleGeneratorState> = {}): BundleGeneratorState => {
  state.bundles = state.bundles ?? [];
  state.loading = state.loading ?? false;
  state.legInfo = state.legInfo ?? {};

  return state as BundleGeneratorState;
};

const sortByLastFreeDay = (a: BundleGeneratorMatchInfo, b: BundleGeneratorMatchInfo) => {
  if (a.lastFreeDay && b.lastFreeDay) {
    return moment(a.lastFreeDay).diff(moment(b.lastFreeDay));
  }

  // If one of the LFDs is missing, sort it to the end
  if (!a.lastFreeDay) {
    return 1;
  }

  if (!b.lastFreeDay) {
    return -1;
  }

  return 0;
};

const bundleGeneratorSlice = createSlice({
  name: 'bundleGenerator',
  initialState: getInitialState(),
  reducers: {
    updateGeneratorBundleCreationStatus: (
      state,
      action: PayloadAction<{ bundleId: string; creationStatus: GeneratorBundle['creationStatus'] }>
    ) => {
      const bundle = state.bundles.find((b) => b.bundleId === action.payload.bundleId);

      if (bundle) {
        bundle.creationStatus = action.payload.creationStatus;

        state.bundles = state.bundles.sort(sortByCreationStatus);
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProspectiveBundles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchProspectiveBundles.fulfilled, (state, action) => {
      const bundles = [...(action.payload?.bundles || [])]
        .sort((legA, legB) => {
          /**
           * NOTE: We're using the last leg of the bundle to sort the bundles as it's the "primary" leg for the only current use case
           * of the bundle generator (reverse-duals). This might need to be changed in the future if we have more use cases
           */
          const a = action.payload?.legInfo[legA.legNumbers[legA.legNumbers.length - 1]];
          const b = action.payload?.legInfo[legB.legNumbers[legB.legNumbers.length - 1]];

          if (!a || !b) {
            return 0;
          }

          // TBD department should always be at the end
          if (a.stops[a.stops.length - 1].departmentUuid === TBD_DEPARTMENT_UUID) {
            return 1;
          }

          if (b.stops[b.stops.length - 1].departmentUuid === TBD_DEPARTMENT_UUID) {
            return -1;
          }

          return 0;
        })
        .sort((legA, legB) => {
          /**
           * NOTE: We're using the last leg of the bundle to sort the bundles as it's the "primary" leg for the only current use case
           * of the bundle generator (reverse-duals). This might need to be changed in the future if we have more use cases
           */
          const a = action.payload?.legInfo[legA.legNumbers[legA.legNumbers.length - 1]];
          const b = action.payload?.legInfo[legB.legNumbers[legB.legNumbers.length - 1]];

          if (!a || !b) {
            return 0;
          }

          return sortByLastFreeDay(a, b);
        })
        .sort(sortByCreationStatus);

      state.bundles = bundles;
      state.legInfo = action.payload?.legInfo || {};
      state.loading = false;
    });
    builder.addCase(fetchProspectiveBundles.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateProspectiveBundleAsCreated.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProspectiveBundleAsCreated.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateProspectiveBundleAsCreated.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updatedProspectiveBundleAsRejected.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatedProspectiveBundleAsRejected.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updatedProspectiveBundleAsRejected.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const selectBundleGeneratorSlice = createSelector(
  selectAdminSlice,
  (state) => state.bundleGenerator as BundleGeneratorState
);

export const selectBundleGeneratorLoading = createSelector(selectBundleGeneratorSlice, (state) => state.loading);

export const selectBundleGeneratorBundles = createSelector(selectBundleGeneratorSlice, (state) => state.bundles);

export const selectBundleGeneratorLegInfo = createSelector(selectBundleGeneratorSlice, (state) => state.legInfo);

export const { updateGeneratorBundleCreationStatus } = bundleGeneratorSlice.actions;

export default bundleGeneratorSlice;
