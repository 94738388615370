// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderInfo {
  display: flex;
  width: 100%;
  padding: 5px;
  justify-content: space-evenly;
  margin-bottom: 15px;
  border-bottom: 1px solid black;
}

  .OrderInfo__section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BundleGenerator/BundleRow/components/BundleRowItem/components/OrderInfo/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,6BAA6B;EAC7B,mBAAmB;EACnB,8BAA8B;AAOhC;;EALE;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB","sourcesContent":[".OrderInfo {\n  display: flex;\n  width: 100%;\n  padding: 5px;\n  justify-content: space-evenly;\n  margin-bottom: 15px;\n  border-bottom: 1px solid black;\n\n  &__section {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
