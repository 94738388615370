import React from 'react';
import GeneratorNotificationRequestEntry from './features/GeneratorNotificationRequestEntry';
import GeneratorNotificationRequestList from './features/GeneratorNotificationRequestList';
import './style.css';

interface Props {
  data: any[];
  inputValue: string;
  sort: { field: string; direction: string };
  onCreate: (notificationRequests: { type: string; data: string }[]) => void;
}

function BundleGeneratorNotifications(props: Props) {
  const baseClassName = 'bundle-generator-notifications';

  return (
    <div className={baseClassName}>
      <h1>Generator Notifications</h1>
      <div className={`${baseClassName}__body`}>
        <GeneratorNotificationRequestEntry onCreate={props.onCreate} inputValue={props.inputValue} />
        <GeneratorNotificationRequestList data={props.data} sort={props.sort} />
      </div>
    </div>
  );
}

export default BundleGeneratorNotifications;
