// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BundleRow {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid lightgray;
  margin-bottom: 5px;
}

  .BundleRow--created {
    background-color: #41A74113;
  }

  .BundleRow--rejected {
    background-color: #ff000013;
  }

  .BundleRow:last-child {
    margin-bottom: 0;
  }

  .BundleRow__action-container {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-bottom: 10px;
  }

  .BundleRow__action-button {
    background-color: #41A741;
    color: #fff;
    margin-right: 15px;
    padding: 8px 10px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
  }

  .BundleRow__action-button:last-child {
      margin-right: 0;
    }

  .BundleRow__action-button--reject {
      background-color: #000000;
    }

  .BundleRow__action-button--loading {
      background-color: #808080;
    }

  .BundleRow__action-button--disabled {
      background-color: #808080;
      pointer-events: none;
    }

  .BundleRow__link-icon {
    background-color: #808080;
    color: #fff;
    height: 24px;
    width: 24px;
    margin: 5px;
    padding: 5px;
    border-radius: 2px;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BundleGenerator/BundleRow/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,2BAA2B;EAC3B,kBAAkB;AAyDpB;;EAvDE;IACE,2BAA2B;EAC7B;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,WAAW;IACX,oBAAoB;IACpB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;EAkBjB;;EAhBE;MACE,eAAe;IACjB;;EAEA;MACE,yBAAyB;IAC3B;;EAEA;MACE,yBAAyB;IAC3B;;EAEA;MACE,yBAAyB;MACzB,oBAAoB;IACtB;;EAGF;IACE,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB","sourcesContent":[".BundleRow {\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n  border: 1px solid lightgray;\n  margin-bottom: 5px;\n\n  &--created {\n    background-color: #41A74113;\n  }\n\n  &--rejected {\n    background-color: #ff000013;\n  }\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n\n  &__action-container {\n    display: flex;\n    width: 100%;\n    justify-content: end;\n    margin-bottom: 10px;\n  }\n\n  &__action-button {\n    background-color: #41A741;\n    color: #fff;\n    margin-right: 15px;\n    padding: 8px 10px;\n    border-radius: 5px;\n    font-weight: 500;\n    cursor: pointer;\n\n    &:last-child {\n      margin-right: 0;\n    }\n\n    &--reject {\n      background-color: #000000;\n    }\n\n    &--loading {\n      background-color: #808080;\n    }\n\n    &--disabled {\n      background-color: #808080;\n      pointer-events: none;\n    }\n  }\n\n  &__link-icon {\n    background-color: #808080;\n    color: #fff;\n    height: 24px;\n    width: 24px;\n    margin: 5px;\n    padding: 5px;\n    border-radius: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
