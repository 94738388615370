// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generator-notification-request-entry {
  width: 50%;
  height: 100%;
  padding-right: 15px;
}

  .generator-notification-request-entry .app-dropdown-select-input {
    width: 50%;
    margin-bottom: 30px;
  }

  .generator-notification-request-entry textarea {
    padding: 20px;
    border: 1px solid var(--color-grayscale-5);
    width: 100%;
    height: 200px;
    outline: none;
  }

  .generator-notification-request-entry__tags {
    display: flex;
    flex-wrap: wrap;
  }

  .generator-notification-request-entry__tags > * {
      margin: 10px 5px;
    }

  .generator-notification-request-entry__btn-group {
    display: flex;
    column-gap: 15px;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BundleGeneratorNotifications/features/GeneratorNotificationRequestEntry/style.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,mBAAmB;AA4BrB;;EA1BE;IACE,UAAU;IACV,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,0CAA0C;IAC1C,WAAW;IACX,aAAa;IACb,aAAa;EACf;;EAEA;IACE,aAAa;IACb,eAAe;EAKjB;;EAHE;MACE,gBAAgB;IAClB;;EAGF;IACE,aAAa;IACb,gBAAgB;EAClB","sourcesContent":[".generator-notification-request-entry {\n  width: 50%;\n  height: 100%;\n  padding-right: 15px;\n\n  .app-dropdown-select-input {\n    width: 50%;\n    margin-bottom: 30px;\n  }\n\n  textarea {\n    padding: 20px;\n    border: 1px solid var(--color-grayscale-5);\n    width: 100%;\n    height: 200px;\n    outline: none;\n  }\n\n  &__tags {\n    display: flex;\n    flex-wrap: wrap;\n\n    & > * {\n      margin: 10px 5px;\n    }\n  }\n\n  &__btn-group {\n    display: flex;\n    column-gap: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
