import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import FileSelectZone from '@client/components/FileSelectZone';
import {
  fetchBulkOperationUpdateHistoryByType,
  selectBulkOperationUpdateLoadingForType,
  selectBulkOperationUpdatesHistoryForType,
  uploadBulkOperationFile
} from './store';
import BulkHistoryRow from './components/BulkHistoryRow';
import { BulkOperationType } from './store/types/BulkOperationTypes';

import './style.css';

interface BulkOperationPageProps {
  title: string;
  operationType: BulkOperationType;
  isEnabled: boolean;
}

function BulkOperationPage({ operationType, title, isEnabled }: BulkOperationPageProps) {
  const baseClassName = 'BulkOperationPage';
  const appDispatch = useAppDispatch();

  const bulkHistory = useSelector(selectBulkOperationUpdatesHistoryForType(operationType));
  const bulkHistoryLoading = useSelector(selectBulkOperationUpdateLoadingForType(operationType));

  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  useEffect(() => {
    const request = appDispatch(fetchBulkOperationUpdateHistoryByType({ operationType }));

    return () => {
      request.abort();
    };
  }, [operationType]);

  if (!isEnabled) {
    return null;
  }

  const handleFilesAccepted = async (files: File[]) => {
    const formData = new FormData();
    const file = files[0];

    formData.append('file', file);
    formData.append('isDryRun', 'false');

    try {
      setIsUploadingFile(true);
      await appDispatch(uploadBulkOperationFile({ formData, operationType })).unwrap();
      setIsUploadingFile(false);

      await appDispatch(fetchBulkOperationUpdateHistoryByType({ operationType, triggerLoadingState: false })).unwrap();
    } catch (err) {
      setUploadError(err.message);
      setIsUploadingFile(false);
    }
  };

  if (bulkHistoryLoading) {
    return (
      <div className={baseClassName}>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <div className={baseClassName}>
      <div>
        <h1>{title}</h1>
      </div>
      <div className={`${baseClassName}__upload-container`}>
        <h2>Upload</h2>
        <FileSelectZone
          async
          isLoading={isUploadingFile}
          className={`${baseClassName}__upload-container-file-select`}
          onFilesAccepted={handleFilesAccepted}
        />
        <div className={`${baseClassName}__upload-container-error`}>{uploadError || ''}</div>
      </div>
      <div className={`${baseClassName}__table-container`}>
        <h2>History</h2>

        <div className={`${baseClassName}__table-header`}>
          <div className={`${baseClassName}__table-header-cell`}>File Name</div>
          <div className={`${baseClassName}__table-header-cell`}>Operation Status</div>
          <div className={`${baseClassName}__table-header-cell`}>Created At</div>
          <div className={`${baseClassName}__table-header-cell`}>Created By</div>
        </div>
        <div className={`${baseClassName}__table-content`}>
          {bulkHistory.map((history) => (
            <BulkHistoryRow key={history.uuid} history={history} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BulkOperationPage;
