// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MatchRowStop {
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BundleGenerator/BundleRow/components/BundleRowStop/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".MatchRowStop {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
