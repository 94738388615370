// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bundle-generator-notifications {
  padding: 0 15px;
  width: 100%;
  height: 100%;
}

  .bundle-generator-notifications__body {
    display: flex;
    width: 100%;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BundleGeneratorNotifications/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,YAAY;AAMd;;EAJE;IACE,aAAa;IACb,WAAW;EACb","sourcesContent":[".bundle-generator-notifications {\n  padding: 0 15px;\n  width: 100%;\n  height: 100%;\n\n  &__body {\n    display: flex;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
