import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { selectCustomers } from '@client/_blessed/store/entities';
import { getDepartmentDisplayName } from '@client/utils/data-processing/department';
import { ShipperOptions, ContainersNotCancelled, Counts } from './stores/models';
import NonCancelledOrders from './features/NonCancelledOrders/component';
import BulkOrderCancellationForm from './features/Form/component';

import './style.css';

function Container() {
  const pageClassName = 'bulk-order-cancellation';

  const [nonCancellableContainers, setNonCancelledContainers] = useState<ContainersNotCancelled[] | []>([]);
  const [counts, setCounts] = useState<Counts | any>({});
  const [cancellationSubmitted, submitCancellation] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const [errorMsg, setErrors] = useState('');

  const shippers = useSelector(selectCustomers) || [];
  const shipperOptions: ShipperOptions[] = shippers.map((shipper) => ({
    uuid: shipper.uuid,
    key: shipper.uuid,
    label: getDepartmentDisplayName(shipper)
  }));

  return (
    <>
      <Helmet title="Bulk Cancel Orders" />
      <div className={pageClassName}>
        <h1 className={`${pageClassName}__h1`}>Bulk Order Cancellation</h1>
        <BulkOrderCancellationForm
          shipperOptions={shipperOptions || []}
          setFetching={setFetching}
          setNonCancelledContainers={setNonCancelledContainers}
          setErrors={setErrors}
          setCounts={setCounts}
          submitCancellation={submitCancellation}
          isFetching={isFetching}
          nonCancellableContainers={nonCancellableContainers}
          cancellationSubmitted={cancellationSubmitted}
          count={counts?.containersNotCancelledCount || 0}
        />
        {errorMsg && <p>{errorMsg}</p>}
        {cancellationSubmitted && nonCancellableContainers.length > 0 && (
          <NonCancelledOrders
            containersNotCancelled={nonCancellableContainers}
            count={counts?.containersNotCancelledCount || 0}
          />
        )}
      </div>
    </>
  );
}

export default Container;
