import React, { useEffect } from 'react';
import DropdownSelectInput from '@client/components/DropdownSelectInput';
import { useSelector } from 'react-redux';
import { fetchChassisPools, selectChassisPoolDropdownOptions } from '@client/_blessed/store/entities';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';

interface Option {
  label: string;
  value: string;
}

interface Props {
  values: Option[];
  // eslint-disable-next-line no-unused-vars
  onChange: (values: Option[]) => void;
}

function ChassisPoolsDropdown(props: Props) {
  const chassisPoolOptions = useSelector(selectChassisPoolDropdownOptions);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchChassisPools());
  }, []);

  return (
    <>
      <h4>Chassis pools (optional):</h4>
      <DropdownSelectInput
        multi
        values={props.values || []}
        options={chassisPoolOptions}
        valueField="value"
        labelField="label"
        onChange={(val: Option[]) => props.onChange(val)}
      />
    </>
  );
}

export default ChassisPoolsDropdown;
