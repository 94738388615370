import type { GeneratorBundle } from '..';

export const sortByCreationStatus = (bundleA: GeneratorBundle, bundleB: GeneratorBundle) => {
  if (bundleA.creationStatus === 'rejected' || bundleA.creationStatus === 'invalidated') {
    return 1;
  }

  if (bundleB.creationStatus === 'rejected' || bundleB.creationStatus === 'invalidated') {
    return -1;
  }

  return 0;
};
