// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BulkHistorySubRow {
  display: flex;
  flex-direction: column;
  margin: 5px 0 5px 20px;
  padding: 5px 10px;
  border: 1px solid #e5e5e5;
}

  .BulkHistorySubRow-completed {
    background-color: #bdfdbd;
  }

  .BulkHistorySubRow-failed {
    background-color: #fbaaaa;
  }

  .BulkHistorySubRow-running {
    background-color: #ffd381;
  }

  .BulkHistorySubRow-submitted {
    background-color: #e6e4e4;
  }

  .BulkHistorySubRow__values-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 20px;
  }

  .BulkHistorySubRow__values-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BulkOperationPage/components/BulkHistorySubRow/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,iBAAiB;EACjB,yBAAyB;AA+B3B;;EA7BE;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAGA;IACE,aAAa;IACb,sBAAsB;IACtB,OAAO;IACP,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB","sourcesContent":[".BulkHistorySubRow {\n  display: flex;\n  flex-direction: column;\n  margin: 5px 0 5px 20px;\n  padding: 5px 10px;\n  border: 1px solid #e5e5e5;\n\n  &-completed {\n    background-color: #bdfdbd;\n  }\n\n  &-failed {\n    background-color: #fbaaaa;\n  }\n\n  &-running {\n    background-color: #ffd381;\n  }\n\n  &-submitted {\n    background-color: #e6e4e4;\n  }\n\n\n  &__values-container {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    margin-left: 20px;\n  }\n\n  &__values-item {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
