import { useSelector } from 'react-redux';
import { DepartmentAttributes } from '@drayalliance/types';

import { selectConsignees, selectYards } from '@client/_blessed/store/entities/departments';
import IconButton from '@client/components/IconButton';
import TrashIcon from '@components/deprecatedTookit/icons/fa/regular/TrashAltIcon';
import { bundleJoinTypeToUIMappings, firstStopTypeToUIMappings } from '@client/constants';
import Department from '@client/_blessed/store/entities/departments/model';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { deleteGeneratorNotificationRequests, updateSort } from '../../store';
import { formatAppointment } from '../../../ReactiveDualTrans/features/utils';
import './style.css';

interface Props {
  data: any[];
  sort: { field: string; direction: string };
}

function GeneratorNotificationRequestList(props: Props) {
  const dispatch = useAppDispatch();
  const consignees = useSelector(selectConsignees);
  const yards = useSelector(selectYards);
  const baseClassName = 'generator-notification-request-list';
  const loadingDepartments = consignees.length === 0 && yards.length === 0;

  const departmentMap: Record<string, any> = {};
  consignees.forEach((consignee: Department) => {
    departmentMap[consignee.uuid] = consignee;
  });
  yards.forEach((yard: Department) => {
    departmentMap[yard.uuid] = yard;
  });

  const handleSort = (field: string) => {
    dispatch(updateSort(field));
  };

  const handleDelete = (requestUuid: string) => {
    dispatch(deleteGeneratorNotificationRequests(requestUuid));
  };

  return (
    <div className={baseClassName}>
      <h3>Active Notification Subscriptions</h3>
      <table>
        <thead>
          <tr>
            <th>
              <button type="button" onClick={() => handleSort('requestorName')}>
                Notify User {props.sort.field === 'requestorName' ? `(${props.sort.direction})` : ''}
              </button>
            </th>
            <th>
              <button type="button" onClick={() => handleSort('data')}>
                Notify Criteria {props.sort.field === 'data' ? `(${props.sort.direction})` : ''}
              </button>
            </th>
            <th>
              <button type="button" onClick={() => handleSort('type')}>
                Type {props.sort.field === 'type' ? `(${props.sort.direction})` : ''}
              </button>
            </th>
            <th>
              <button type="button">First Stop Type</button>
            </th>
            <th>
              <button type="button">First Stop Departments</button>
            </th>
            <th>
              <button type="button">Chassis Pools</button>
            </th>
            <th>
              <button type="button" onClick={() => handleSort('expireAt')}>
                Subscription Expires {props.sort.field === 'expireAt' ? `(${props.sort.direction})` : ''}
              </button>
            </th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((item) => {
            const parsedData = JSON.parse(item.data);
            const notifyCriteria = [];
            if (parsedData.legNumber) {
              notifyCriteria.push(`Leg: ${parsedData.legNumber}`);
            }
            if (parsedData.requireOnHireConfirmation) {
              notifyCriteria.push(`On-hire: Yes`);
            }
            if (parsedData.requireYardConfirmation) {
              notifyCriteria.push(`In yard: Yes`);
            }

            const mappedDepartments: DepartmentAttributes[] = [];
            if (parsedData.firstStopDepartmentUuids) {
              parsedData.firstStopDepartmentUuids.forEach((departmentUuid: string) => {
                if (departmentMap[departmentUuid]) {
                  mappedDepartments.push(departmentMap[departmentUuid]);
                }
              });
            }

            const chassisPoolNames: string[] = [];
            if (parsedData.chassisPools) {
              parsedData.chassisPools.forEach((chassisPool: { name: string }) => {
                chassisPoolNames.push(chassisPool.name);
              });
            }

            let mappedDepartmentsComponent = [<div>None</div>];
            if (mappedDepartments.length > 0) {
              mappedDepartmentsComponent = mappedDepartments.map((department) => (
                <div key={department.uuid}>
                  ({department.type}) {department.name}
                </div>
              ));
            }

            return (
              <tr key={item.uuid}>
                <td>
                  {item.requestor?.firstName} {item.requestor?.lastName}
                </td>
                <td>
                  {notifyCriteria.map((criteria) => (
                    <div>{criteria}</div>
                  ))}
                </td>
                <td>{(bundleJoinTypeToUIMappings as Record<string, string>)[item.type]}</td>
                <td>{(firstStopTypeToUIMappings as Record<string, string>)[parsedData.firstStopType || 'any']}</td>
                <td>{loadingDepartments ? 'Loading...' : mappedDepartmentsComponent}</td>
                <td>
                  {chassisPoolNames.map((chassisPoolName) => (
                    <div>{chassisPoolName}</div>
                  ))}
                </td>
                <td>{formatAppointment(item.expireAt)}</td>
                <td>
                  <IconButton floating icon={<TrashIcon />} disabled={false} onClick={() => handleDelete(item.uuid)} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default GeneratorNotificationRequestList;
