// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bulk-order-cancellation__h1 {
    padding-bottom: 30px;
    font-size: 20px;
    font-weight: 600;
    margin-top: 0;
  }
  .bulk-order-cancellation {

  padding: 40px;
}
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BulkOrderCancellation/style.css","<no source>"],"names":[],"mappings":"AACE;IACE,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,aAAa;EACf;ECNF;;EDQE;CCRF","sourcesContent":[".bulk-order-cancellation {\n  &__h1 {\n    padding-bottom: 30px;\n    font-size: 20px;\n    font-weight: 600;\n    margin-top: 0;\n  }\n\n  padding: 40px;\n}\n",null],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
