import { createSelector } from '@reduxjs/toolkit';
import { selectAdminSlice } from '../../../store/selectors';
import { NotificationRequest, sliceId } from '.';

const sortNotificationRequestsBy = (notificationRequests: NotificationRequest[], sort: any) => {
  const sorter = (a: NotificationRequest, b: NotificationRequest) => {
    let aVal = a[sort.field as keyof NotificationRequest];
    let bVal = b[sort.field as keyof NotificationRequest];
    if (sort.field === 'requestorName') {
      aVal = `${a.requestor?.firstName} ${a.requestor?.lastName}`;
      bVal = `${b.requestor?.firstName} ${b.requestor?.lastName}`;
    }
    if (sort.field === 'expireAt') {
      aVal = aVal || '';
      bVal = bVal || '';
    }
    if (sort.direction === 'ASC') {
      return (aVal as string) < (bVal as string) ? -1 : 1;
    }
    return (aVal as string) > (bVal as string) ? -1 : 1;
  };
  return [...notificationRequests].sort(sorter);
};

export const selectGeneratorNotificationsSlice = createSelector(selectAdminSlice, (state) => state[sliceId]);

export const selectAllGeneratorNotificationRequests = createSelector(
  selectGeneratorNotificationsSlice,
  (state) => state.notificationRequests
);

export const selectSorting = createSelector(selectGeneratorNotificationsSlice, (state) => state.sorting);

export const selectSortedGeneratorNotificationRequests = createSelector(
  selectAllGeneratorNotificationRequests,
  selectSorting,
  (notificationRequests, sorting) => sortNotificationRequestsBy(notificationRequests, sorting)
);
