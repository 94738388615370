import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectConsignees, selectYards } from '@client/_blessed/store/entities/departments';
import Button from '@client/components/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import DropdownSelectInput from '@client/components/DropdownSelectInput';
import normalizeDelimitedTextareaInput from '@client/utils/normalizeDelimitedTextareaValue';
import { bundleJoinTypeToUIMappings, firstStopTypeToUIMappings } from '@client/constants';
import Tag from '@client/components/Tag';
import Department from '@client/_blessed/store/entities/departments/model';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { updateInputValue } from '../../store';
import ChassisPoolsDropdown from './components/chassisPoolsDropdown';

import './style.css';

interface Props {
  inputValue: string;
  // eslint-disable-next-line no-unused-vars
  onCreate: (notificationRequests: { type: string; data: string }[]) => void;
}

interface ChassisPool {
  name: string;
  uuid: string;
}

export interface GeneratorNotificationRequestData {
  legNumber: string;
  firstStopType?: string;
  firstStopDepartmentUuids?: string[];
  chassisPools?: ChassisPool[];
  requireOnHireConfirmation?: boolean;
  requireYardConfirmation?: boolean;
}

function GeneratorNotificationRequestEntry(props: Props) {
  const dispatch = useAppDispatch();
  const consignees = useSelector(selectConsignees);
  const yards = useSelector(selectYards);
  const featFlags = useFlags();
  const enabledRequestTypes = featFlags.enabledBundleGeneratorNotificationTypes?.enabledTypes || [];
  const enabledFirstStopTypes = featFlags.enabledBundleGeneratorNotificationTypes?.enabledFirstStopTypes || [
    'any',
    'yard',
    'consignee'
  ];

  const requestTypeOptions: { value: string; label: string }[] = [];
  const yesOption = { value: true, label: 'Yes' };
  const noOption = {
    value: false,
    label: 'No'
  };
  const yesOrNoOptions: { value: boolean; label: string }[] = [yesOption, noOption];
  const requestFirstStopTypeOptions: { value: string; label: string }[] = [];
  const requestFirstStopConsigneeOptions: { value: string; label: string }[] = [];
  const requestFirstStopYardOptions: { value: string; label: string }[] = [];

  enabledRequestTypes.forEach((typeKey: string) => {
    const label = (bundleJoinTypeToUIMappings as Record<string, string>)[typeKey];
    if (label) {
      requestTypeOptions.push({ value: typeKey, label });
    }
  });
  enabledFirstStopTypes.forEach((typeKey: string) => {
    const label = (firstStopTypeToUIMappings as Record<string, string>)[typeKey] || `Missing Mapping Label: ${typeKey}`;
    if (label) {
      requestFirstStopTypeOptions.push({ value: typeKey, label });
    }
  });
  consignees.forEach((consignee: Department) => {
    requestFirstStopConsigneeOptions.push({ value: consignee.uuid, label: consignee.name });
  });
  yards.forEach((yard: Department) => {
    requestFirstStopYardOptions.push({ value: yard.uuid, label: yard.name });
  });

  const [requestType, setRequestType] = useState(requestTypeOptions[0] ? [requestTypeOptions[0]] : []);
  const [requireOnHireConfirmation, setRequireOnHireConfirmation] = useState([yesOption]);
  const [requireYardConfirmation, setRequireYardConfirmation] = useState([yesOption]);
  const [chassisPools, setChassisPools] = useState<{ label: string; value: string }[]>([]);
  const [firstStopType, setFirstStopType] = useState(
    requestFirstStopTypeOptions[0] ? [requestFirstStopTypeOptions[0]] : []
  );
  const [firstStopConsignees, setFirstStopConsignees] = useState<{ value: string; label: string }[]>([]);
  const [firstStopYards, setFirstStopYards] = useState<{ value: string; label: string }[]>([]);
  const baseClassName = 'generator-notification-request-entry';
  const enabledAllDualsConfirmedOption = featFlags.bundleGeneratorTags && requestType[0].value === 'dualTrans';

  const clearFirstStopFilters = () => {
    // Clear selected departments
    setFirstStopConsignees([]);
    setFirstStopYards([]);
  };

  const handleSubmit = () => {
    const normalizedLegNumbers = normalizeDelimitedTextareaInput(props.inputValue);
    const legNumbers = normalizedLegNumbers.split(' ');
    const notificationRequests = legNumbers.map((legNumber) => {
      const notificationData: GeneratorNotificationRequestData = {
        legNumber
      };
      if (enabledAllDualsConfirmedOption) {
        notificationData.chassisPools = chassisPools.map((chassisPool) => ({
          name: chassisPool.label,
          uuid: chassisPool.value
        }));
        notificationData.requireOnHireConfirmation = requireOnHireConfirmation[0].value;
        notificationData.requireYardConfirmation = requireYardConfirmation[0].value;
      }
      if (firstStopType[0].value !== 'any') {
        notificationData.firstStopType = firstStopType[0].value;
      }
      if (firstStopConsignees.length > 0 || firstStopYards.length > 0) {
        const firstStopConsigneeUuids = firstStopConsignees.map((fsc) => fsc.value);
        const firstStopYardUuids = firstStopYards.map((fsy) => fsy.value);
        notificationData.firstStopDepartmentUuids = [...firstStopConsigneeUuids, ...firstStopYardUuids];
      }
      return {
        type: requestType[0].value,
        data: JSON.stringify(notificationData)
      };
    });
    props.onCreate(notificationRequests);
    clearFirstStopFilters();
  };

  const handleInputChange = (event: any) => {
    dispatch(updateInputValue(event.target.value));
  };

  return (
    <div className={baseClassName}>
      <h3>Subscribe to notifications</h3>
      <div>
        <textarea
          value={props.inputValue}
          placeholder="Enter leg numbers here separated by space, comma, or hard return"
          onChange={handleInputChange}
          aria-label="Leg numbers input"
        />
        <h4>Notify on bundle types:</h4>
        <DropdownSelectInput
          multi={false}
          values={requestType}
          value="dualTrans"
          options={requestTypeOptions}
          valueField="value"
          labelField="label"
          onChange={(val: any[]) => setRequestType(val)}
        />
        {/* only display on hire, yard, and chassis pool options if dual trans is selected */}
        {enabledAllDualsConfirmedOption && (
          <>
            <h4>Require on-hire confirmation:</h4>
            <DropdownSelectInput
              multi={false}
              values={requireOnHireConfirmation}
              value="Yes"
              options={yesOrNoOptions}
              valueField="value"
              labelField="label"
              onChange={(val: any[]) => setRequireOnHireConfirmation(val)}
            />
          </>
        )}
        {enabledAllDualsConfirmedOption && (
          <>
            <h4>Require yard confirmation:</h4>
            <DropdownSelectInput
              multi={false}
              values={requireYardConfirmation}
              value="Yes"
              options={yesOrNoOptions}
              valueField="value"
              labelField="label"
              onChange={(val: any[]) => setRequireYardConfirmation(val)}
            />
          </>
        )}
        {enabledAllDualsConfirmedOption && <ChassisPoolsDropdown values={chassisPools} onChange={setChassisPools} />}
        <h4>Notify on first stop type:</h4>
        <DropdownSelectInput
          multi={false}
          values={firstStopType}
          value="any"
          options={requestFirstStopTypeOptions}
          valueField="value"
          labelField="label"
          onChange={(val: any[]) => setFirstStopType(val)}
        />
        {firstStopType[0].value === 'consignee' && (
          <>
            <h4>Notify on first stop consignees(s):</h4>
            <DropdownSelectInput
              multi
              placeholder="All Consignees"
              groupName="consignees"
              values={firstStopConsignees}
              options={requestFirstStopConsigneeOptions}
              valueField="value"
              labelField="label"
              onChange={(val: any[]) => {
                setFirstStopConsignees(val);
              }}
              noContentUpdate
            />

            <div className={`${baseClassName}__tags`}>
              {firstStopConsignees &&
                firstStopConsignees.map((item, index) => (
                  <Tag
                    key={item.value}
                    index={index}
                    groupName="consignees"
                    prefix="Consignee:"
                    label={item.label}
                    onDelete={() => {
                      const newConsignees = [...firstStopConsignees];
                      newConsignees.splice(index, 1);
                      setFirstStopConsignees(newConsignees);
                    }}
                  />
                ))}
            </div>
          </>
        )}
        {firstStopType[0].value === 'yard' && (
          <>
            <h4>Notify on first stop yard(s):</h4>
            <DropdownSelectInput
              multi
              placeholder="All Yards"
              groupName="yards"
              values={firstStopYards}
              options={requestFirstStopYardOptions}
              valueField="value"
              labelField="label"
              onChange={(val: any[]) => {
                setFirstStopYards(val);
              }}
              noContentUpdate
            />

            <div className={`${baseClassName}__tags`}>
              {firstStopYards &&
                firstStopYards.map((item, index) => (
                  <Tag
                    key={item.value}
                    index={index}
                    groupName="yards"
                    prefix="Yard:"
                    label={item.label}
                    onDelete={() => {
                      const newYards = [...firstStopYards];
                      newYards.splice(index, 1);
                      setFirstStopYards(newYards);
                    }}
                  />
                ))}
            </div>
          </>
        )}
      </div>
      <div className={`${baseClassName}__btn-group`}>
        {(firstStopConsignees.length > 0 || firstStopYards.length > 0) && (
          <Button
            theme="2"
            label="Clear Selection"
            loading={false}
            type="button"
            onClick={() => clearFirstStopFilters()}
          />
        )}
        <Button theme="2" label="Subscribe to Notifications" loading={false} type="button" onClick={handleSubmit} />
      </div>
    </div>
  );
}

export default GeneratorNotificationRequestEntry;
