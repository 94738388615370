// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containers-not-cancelled {
  display: grid;
  margin-top: 50px;
  grid-gap: 5px;
  position: relative;
}

  .containers-not-cancelled__table {
    display: grid;
    overflow: auto;
    max-width: 875px;
    width: calc(100vw - 50%);
  }

  .containers-not-cancelled__h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
  }

  .containers-not-cancelled__reason {
    font-size: 1.2rem;
    padding: 15px;
    background: var(--color-grayscale-2);
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BulkOrderCancellation/features/NonCancelledOrders/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,aAAa;EACb,kBAAkB;AAoBpB;;EAlBE;IACE,aAAa;IACb,cAAc;IACd,gBAAgB;IAChB,wBAAwB;EAC1B;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,SAAS;EACX;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,oCAAoC;EACtC","sourcesContent":[".containers-not-cancelled {\n  display: grid;\n  margin-top: 50px;\n  grid-gap: 5px;\n  position: relative;\n\n  &__table {\n    display: grid;\n    overflow: auto;\n    max-width: 875px;\n    width: calc(100vw - 50%);\n  }\n\n  &__h2 {\n    font-size: 1.2rem;\n    font-weight: 600;\n    margin: 0;\n  }\n\n  &__reason {\n    font-size: 1.2rem;\n    padding: 15px;\n    background: var(--color-grayscale-2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
