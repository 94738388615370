import LinkIcon from '@components/deprecatedTookit/icons/fa/light/LinkIcon';
import { DeliveryPreference } from '@client/_blessed/store/entities/departments/model';
import BundleRowItem from './components/BundleRowItem';
import { BundleGeneratorMatchInfo, GeneratorBundle } from '../store';

import './style.css';

interface BundleRowProps {
  bundle: GeneratorBundle & { legs: BundleGeneratorMatchInfo[] };
  onMergeClick: (mergeInfo: {
    bundleId: string;
    primary: BundleGeneratorMatchInfo;
    match: BundleGeneratorMatchInfo;
  }) => Promise<void>;
  onMergeAndPostClick: (mergeInfo: {
    bundleId: string;
    primary: BundleGeneratorMatchInfo;
    match: BundleGeneratorMatchInfo;
  }) => Promise<void>;
  onRejectClick: (mergeInfo: { bundleId: string }) => Promise<void>;
  isMergingBundles: boolean;
}

const determinePrimaryAndMatch = (bundle: GeneratorBundle & { legs: BundleGeneratorMatchInfo[] }) => {
  switch (bundle.type) {
    // TODO: Remove this case; It's only here for legacy reasons
    case 'reverse-duals':
    case 'terminal-dual':
      return { primary: bundle.legs[bundle.legs.length - 1], match: bundle.legs[0] };
    default:
      return { primary: bundle.legs[0], match: bundle.legs[bundle.legs.length - 1] };
  }
};

function BundleRow({ bundle, onMergeClick, onMergeAndPostClick, onRejectClick, isMergingBundles }: BundleRowProps) {
  let baseClassName = 'BundleRow';

  if (bundle.creationStatus === 'rejected' || bundle.creationStatus === 'invalidated') {
    baseClassName = `${baseClassName}--rejected ${baseClassName}`;
  } else if (bundle.creationStatus === 'created') {
    baseClassName = `${baseClassName}--created ${baseClassName}`;
  }

  const { primary, match } = determinePrimaryAndMatch(bundle);

  const primaryConsigneeDepartment = primary.stops.find((stop) => stop.type === 'consignee')?.department;
  let primaryConsigneeDeliveryPreference: DeliveryPreference<any> | null = null;
  if (primaryConsigneeDepartment) {
    primaryConsigneeDeliveryPreference =
      primary.importExport === 'import'
        ? primaryConsigneeDepartment.importDeliveryPreference || null
        : primaryConsigneeDepartment.exportDeliveryPreference || null;
  }

  const matchConsigneeDepartment = match.stops.find((stop) => stop.type === 'consignee')?.department;
  let matchConsigneeDeliveryPreference: DeliveryPreference<any> | null = null;
  if (matchConsigneeDepartment) {
    matchConsigneeDeliveryPreference =
      match.importExport === 'import'
        ? matchConsigneeDepartment.importDeliveryPreference || null
        : matchConsigneeDepartment.exportDeliveryPreference || null;
  }

  const canMergeAndPost =
    primaryConsigneeDeliveryPreference?.openTwentyFourHours && matchConsigneeDeliveryPreference?.openTwentyFourHours;
  let mergeAndPostButtonClassName = `${baseClassName}__action-button`;
  if (!canMergeAndPost) {
    mergeAndPostButtonClassName = `${baseClassName}__action-button--disabled ${mergeAndPostButtonClassName}`;
  }

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__action-container`}>
        <div
          onClick={() => onMergeClick({ bundleId: bundle.bundleId, primary, match })}
          className={`${
            isMergingBundles ? `${baseClassName}__action-button--loading` : ''
          }${baseClassName}__action-button`}
        >
          Merge
        </div>
        <div
          className={`${
            isMergingBundles ? `${baseClassName}__action-button--loading` : ''
          }${mergeAndPostButtonClassName}`}
          onClick={() => {
            if (canMergeAndPost) {
              onMergeAndPostClick({ bundleId: bundle.bundleId, primary, match });
            }
          }}
        >
          Merge & Post
        </div>
        <div
          className={`${bundle.creationStatus === 'rejected' ? `${baseClassName}__action-button--disabled ` : ''}${
            isMergingBundles ? `${baseClassName}__action-button--loading ` : ''
          }${baseClassName}__action-button--reject ${baseClassName}__action-button`}
          onClick={() => {
            onRejectClick({ bundleId: bundle.bundleId });
          }}
        >
          Reject
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
        {bundle.legs.map((leg, index) => (
          <>
            <BundleRowItem key={leg.legNumber} info={leg} />
            {index !== bundle.legs.length - 1 && <LinkIcon className={`${baseClassName}__link-icon`} />}
          </>
        ))}
      </div>
    </div>
  );
}

export default BundleRow;
