import normalizeDelimitedTextareaInput from '@client/utils/normalizeDelimitedTextareaValue';
import { api } from '@client/utils/url';
import request from '@client/utils/request';
import { CancellableOrdersData } from './models';

export async function getCancellableOrdersData(queries: {
  billingAccountUuid: string;
  containerNumbers: string;
}): Promise<CancellableOrdersData> {
  const { billingAccountUuid, containerNumbers } = queries;
  const normalizedContainerNumbers = normalizeDelimitedTextareaInput(containerNumbers);
  const splitContainerNumbers = normalizedContainerNumbers.split(' ');
  const url = api(`/orders/cancel-orders-payload/${billingAccountUuid}?containerNumbers=${splitContainerNumbers}`);
  const opts = { method: 'GET' };
  const result = (await request(url, opts)) as CancellableOrdersData;
  return result;
}
