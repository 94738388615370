import React, { ReactElement } from 'react';
import { ContainersNotCancelled } from '../../stores/models';
import ContainerNumbers from '../ContainerNumbers/component';
import './style.css';

interface Props {
  containersNotCancelled: ContainersNotCancelled[];
  count: number;
}

function NonCancelledOrders({ containersNotCancelled, count }: Props): ReactElement {
  const baseClassName = 'containers-not-cancelled';

  return (
    <div className={baseClassName}>
      <h2 className={`${baseClassName}__h2`}>Containers not cancellable</h2>
      <div>Non-cancellable count: {count}</div>
      {containersNotCancelled.map(({ reason, containerNumbers }) => (
        <div className={`${baseClassName}__table`}>
          <div className={`${baseClassName}__reason`}>
            <b>Reason</b>: {reason}
          </div>
          <ContainerNumbers containerNumbers={containerNumbers} />
        </div>
      ))}
    </div>
  );
}

export default React.memo(NonCancelledOrders);
