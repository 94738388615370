// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BundleGenerator {
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 1250px;
  height: 100%;
  overflow-y: scroll;
  padding: 10px;
}

  .BundleGenerator--container-number-filter {
    border: 1px solid black;
    border-radius: 2px;
    margin-bottom: 5px;
    padding: 5px 10px;
  }

  .BundleGenerator--loading {
    align-self: center;
    width: 10%;
  }

  .BundleGenerator--message {
    align-self: center;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BundleGenerator/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,aAAa;AAiBf;;EAfE;IACE,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,UAAU;EACZ;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":[".BundleGenerator {\n  display: flex;\n  flex-direction: column;\n  justify-items: center;\n  width: 1250px;\n  height: 100%;\n  overflow-y: scroll;\n  padding: 10px;\n\n  &--container-number-filter {\n    border: 1px solid black;\n    border-radius: 2px;\n    margin-bottom: 5px;\n    padding: 5px 10px;\n  }\n\n  &--loading {\n    align-self: center;\n    width: 10%;\n  }\n\n  &--message {\n    align-self: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
