// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BulkHistoryRow {
  display: flex;
  cursor: pointer;
  height: 50px;
}

  .BulkHistoryRow__loading {
    display: flex;
    align-items: center;
    height: 50px;
  }

  .BulkHistoryRow-completed {
    background-color: #bdfdbd;
  }

  .BulkHistoryRow-partailly-completed {
    background-color: #ffffab;;
  }

  .BulkHistoryRow-failed {
    background-color: #fbaaaa;
  }

  .BulkHistoryRow-running {
    background-color: #ffd381;
  }

  .BulkHistoryRow-submitted {
    background-color: #e6e4e4;
  }

  .BulkHistoryRow-cell {
    display: flex;
    align-items: center;
    width: 25%;
    max-width: 25%;
    border: 1px solid black;
    padding: 5px;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BulkOperationPage/components/BulkHistoryRow/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,YAAY;AAoCd;;EAlCE;IACE,aAAa;IACb,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,cAAc;IACd,uBAAuB;IACvB,YAAY;EACd","sourcesContent":[".BulkHistoryRow {\n  display: flex;\n  cursor: pointer;\n  height: 50px;\n\n  &__loading {\n    display: flex;\n    align-items: center;\n    height: 50px;\n  }\n\n  &-completed {\n    background-color: #bdfdbd;\n  }\n\n  &-partailly-completed {\n    background-color: #ffffab;;\n  }\n\n  &-failed {\n    background-color: #fbaaaa;\n  }\n\n  &-running {\n    background-color: #ffd381;\n  }\n\n  &-submitted {\n    background-color: #e6e4e4;\n  }\n\n  &-cell {\n    display: flex;\n    align-items: center;\n    width: 25%;\n    max-width: 25%;\n    border: 1px solid black;\n    padding: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
