export const BULK_OPERATION_TYPES = {
  FSC_UPDATE: 'fsc_update',
  LOAD_ID_UPDATE: 'load_id_update',
  INVOICE_PDF_GENERATION: 'invoice_pdf_generation',
  ORDER_STATUS_UPDATE: 'update_order_status'
} as const;
export type BulkOperationType = (typeof BULK_OPERATION_TYPES)[keyof typeof BULK_OPERATION_TYPES];

export const BULK_OPERATION_TYPES_TO_UPLOAD = {
  [BULK_OPERATION_TYPES.FSC_UPDATE]: 'fsc-update',
  [BULK_OPERATION_TYPES.LOAD_ID_UPDATE]: 'load-id-update',
  [BULK_OPERATION_TYPES.INVOICE_PDF_GENERATION]: 'invoice-pdf-generation',
  [BULK_OPERATION_TYPES.ORDER_STATUS_UPDATE]: 'order-status-update'
} as const;
export type BulkOperationTypeToQuery =
  (typeof BULK_OPERATION_TYPES_TO_UPLOAD)[keyof typeof BULK_OPERATION_TYPES_TO_UPLOAD];
