import { BundleGeneratorMatchInfo } from '../../../store';
import MatchRowStop from '../BundleRowStop';
import OrderInfo from './components/OrderInfo';

import './style.css';

interface BundleRowItemProps {
  info: BundleGeneratorMatchInfo;
}

function BundleRowItem({ info }: BundleRowItemProps) {
  const baseClassName = 'BundleRowItem';

  return (
    <div className={baseClassName}>
      <OrderInfo matchInfo={info} />

      <div className={`${baseClassName}__bottom-section-container`}>
        <div>
          <h3>Leg: {info.legNumber}</h3>

          <div className={`${baseClassName}__stops-container`}>
            {info.stops.map((stop, index) => (
              <MatchRowStop
                key={stop.uuid}
                stop={stop}
                stopIndex={index}
                isFirstOfLeg={index === 0}
                isLastOfLeg={index === info.stops.length - 1}
                status={index !== info.stops.length - 1 ? 'ready' : null}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BundleRowItem;
