import { useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import LoadingSpinner from '@client/components/LoadingSpinner/component';
import { selectDepartmentsLoading } from '@client/_blessed/store/entities';
import { mergeBundles, setBundleSync, syncBundleDepartments } from '@client/state/resources/bundle/actions';
import { logAnalyticsClick, logAnalyticsPageView } from '@client/utils/analytics';
import moment from 'moment-timezone';
import { updateStopInstants } from '@client/state/resources/stop-instants/actions';
import { updateLegs } from '@client/state/resources/legs/actions';
import { openTab } from '@client/utils/url';
import {
  BundleGeneratorMatchInfo,
  fetchProspectiveBundles,
  selectBundleGeneratorLoading,
  selectBundleGeneratorLegInfo,
  selectBundleGeneratorBundles,
  updateProspectiveBundleAsCreated,
  updatedProspectiveBundleAsRejected,
  GeneratorBundle,
  updateGeneratorBundleCreationStatus
} from './store';
import { fetchLegStopActions, selectLegStopActionsLoading } from '../../../Dispatch/store';

import './style.css';
import BundleRow from './BundleRow/component';

const PAGE_TITLE = 'Dual Trans Bundle Generator';

interface BundleGeneratorProps {
  legNumber?: number;
}

function BundleGenerator(props: BundleGeneratorProps) {
  const baseClassName = 'BundleGenerator';

  const appDispatch = useAppDispatch();

  const isDepartmentsLoading = useSelector(selectDepartmentsLoading);
  const isLegStopActionsLoading = useSelector(selectLegStopActionsLoading);
  const isLoading = useSelector(selectBundleGeneratorLoading);

  const bundleGeneratorBundles = useSelector(selectBundleGeneratorBundles);
  const bundleGeneratorMatchInfo = useSelector(selectBundleGeneratorLegInfo);

  const [containerFilter, setContainerFilter] = useState('');

  const [isMergingBundles, setIsMergingBundles] = useState(false);
  const [mergingMessage, setMergingMessage] = useState<string | null>(null);
  const [mergedMessage, setMergedMessage] = useState<string | null>(null);
  const [mergedLegNumbers, setMergedLegNumbers] = useState<string[]>([]);

  // eslint-disable-next-line arrow-body-style
  const filteredBundleGeneratorBundles = useMemo(() => {
    return bundleGeneratorBundles.reduce<Array<GeneratorBundle & { legs: BundleGeneratorMatchInfo[] }>>(
      (accum, bundle) => {
        const bundleLegs = bundle.legNumbers.map((legNumber) => bundleGeneratorMatchInfo[legNumber]);

        // Filter out bundles that match the container filter
        if (
          containerFilter &&
          !bundleLegs.some((leg) => leg.containerNumber.toLowerCase().includes(containerFilter.toLowerCase()))
        ) {
          return accum;
        }

        accum.push({
          ...bundle,
          legs: bundleLegs
        });

        return accum;
      },
      []
    );
  }, [containerFilter, bundleGeneratorBundles, bundleGeneratorMatchInfo]);

  const mergePrimaryAndMatchBundles = useCallback(
    async ({
      bundleId,
      primary,
      match
    }: {
      bundleId: string;
      primary: BundleGeneratorMatchInfo;
      match: BundleGeneratorMatchInfo;
    }) => {
      const { bundle: newBundle } = await mergeBundles({
        data: {
          primaryBundleUuid: primary.bundleUuid,
          secondaryBundleUuid: match.bundleUuid,
          bundleType: 'dualTrans',
          prepend: true
        }
      });

      if (newBundle) {
        await setBundleSync(
          {
            bundleUuid: newBundle.uuid,
            primaryStopInstant: primary.stops.find((stop) => stop.type === 'terminal')!.departureStopInstant.uuid,
            sync: true
          },
          undefined,
          true
        );

        await syncBundleDepartments({
          bundleUuid: newBundle.uuid,
          positionToSyncFrom: 1,
          positionToSyncTo: 0
        });

        await appDispatch(
          updateProspectiveBundleAsCreated({
            bundleIds: [bundleId]
          })
        );

        await updateLegs([
          {
            type: 'leg',
            id: primary.legUuid,
            attributes: {
              type: 'drop_only'
            }
          }
        ])();
      }
    },
    [appDispatch, updateProspectiveBundleAsCreated]
  );

  const handleBundleJoinClick = async ({
    bundleId,
    primary,
    match
  }: {
    bundleId: string;
    primary: BundleGeneratorMatchInfo;
    match: BundleGeneratorMatchInfo;
  }) => {
    try {
      logAnalyticsClick('merge', 'bundle-generator', {
        primarylegNumber: primary.legNumber,
        matchLegNumber: match.legNumber
      });
      setIsMergingBundles(true);

      setMergingMessage('Merging Bundles...');
      await mergePrimaryAndMatchBundles({ bundleId, primary, match });

      setMergedMessage('Bundles Merged Successfully!');
      setMergedLegNumbers([`${primary.legNumber}`, `${match.legNumber}`]);

      setIsMergingBundles(false);
      setMergingMessage(null);
    } catch (err) {
      setIsMergingBundles(false);
    }
  };

  const handleBundleJoinAndPostClick = async ({
    bundleId,
    primary,
    match
  }: {
    bundleId: string;
    primary: BundleGeneratorMatchInfo;
    match: BundleGeneratorMatchInfo;
  }) => {
    try {
      logAnalyticsClick('merge & post', 'bundle-generator', {
        primarylegNumber: primary.legNumber,
        matchLegNumber: match.legNumber
      });

      setIsMergingBundles(true);

      // Merge the bundles
      setMergingMessage('Merging Bundles...');
      await mergePrimaryAndMatchBundles({ bundleId, primary, match });

      // Update the match consignee appointment to be 3 hours before the terminal appointment
      const primaryTerminalStopIndex = primary.stops.findIndex((stop) => stop.type === 'terminal');
      const primaryTerminalStop = primary.stops[primaryTerminalStopIndex];
      const primaryTerminalStopInstant =
        primaryTerminalStopIndex === 0
          ? primaryTerminalStop.departureStopInstant
          : primaryTerminalStop.arrivalStopInstant;

      const primaryConsigneeStopIndex = primary.stops.findIndex((stop) => stop.type === 'consignee');
      const primaryConsigneeStop = primary.stops[primaryConsigneeStopIndex];
      const primaryConsigneeStopInstant =
        primaryConsigneeStopIndex === 0
          ? primaryConsigneeStop.departureStopInstant
          : primaryConsigneeStop.arrivalStopInstant;

      const matchConsigneeStopIndex = match.stops.findIndex((stop) => stop.type === 'consignee');
      const matchConsigneeStop = match.stops[matchConsigneeStopIndex];
      const matchConsigneeStopInstant =
        matchConsigneeStopIndex === 0 ? matchConsigneeStop.departureStopInstant : matchConsigneeStop.arrivalStopInstant;

      setMergingMessage('Updating return appointment times...');
      await updateStopInstants([
        {
          id: matchConsigneeStopInstant.uuid,
          type: 'stopInstant',
          attributes: {
            appointmentStart: moment(primaryTerminalStopInstant.appointmentStart!)
              .tz(primaryTerminalStop.department!.timezone)
              .subtract(3, 'hours')
              .format(),
            appointmentEnd: moment(primaryTerminalStopInstant.appointmentEnd!)
              .tz(primaryTerminalStop.department!.timezone)
              .subtract(3, 'hours')
              .format()
          }
        }
      ])();

      setMergingMessage('Updating pickup leg consignee appointment...');
      await updateStopInstants([
        {
          id: primaryConsigneeStopInstant.uuid,
          type: 'stopInstant',
          attributes: {
            appointmentStart: moment(primaryTerminalStopInstant.appointmentStart!)
              .tz(primaryTerminalStop.department!.timezone)
              .add(3, 'hours')
              .format(),
            appointmentEnd: moment(primaryTerminalStopInstant.appointmentEnd!)
              .tz(primaryTerminalStop.department!.timezone)
              .add(3, 'hours')
              .format()
          }
        }
      ])();

      // Post the bundle to the job board (aka. ready for pickup)
      setMergingMessage('Posting bundle to job board...');
      await updateLegs([
        {
          type: 'leg',
          id: primary.legUuid,
          attributes: {
            status: 'ready_for_pickup'
          }
        }
      ])();

      setMergedMessage('Bundles Merged and Posted Successfully!');
      setMergedLegNumbers([`${primary.legNumber}`, `${match.legNumber}`]);

      setIsMergingBundles(false);
      setMergingMessage(null);
    } catch (err) {
      setIsMergingBundles(false);
    }
  };

  const handleBundleRejectClick = async ({ bundleId }: { bundleId: string }) => {
    try {
      await appDispatch(
        updatedProspectiveBundleAsRejected({
          bundles: [
            {
              bundleId,
              feedback: 'Not Implemented' // TODO: Add actual feedback from user
            }
          ]
        })
      );
    } catch (err) {
      // Do nothing
    }

    appDispatch(
      updateGeneratorBundleCreationStatus({
        bundleId,
        creationStatus: 'rejected'
      })
    );
  };

  useEffect(() => {
    logAnalyticsPageView('dispatch/bundle-generator');
  }, []);

  useEffect(() => {
    const bundleGenPromise = appDispatch(
      fetchProspectiveBundles({
        legNumbers: props.legNumber ? [props.legNumber] : undefined
      })
    );

    const legStopActionsPromise = appDispatch(fetchLegStopActions());

    return () => {
      bundleGenPromise.abort();
      legStopActionsPromise.abort();

      if (mergedMessage) {
        openTab({
          filters: btoa(JSON.stringify({ legNumber: mergedLegNumbers }))
        });
      }
    };
  }, [props.legNumber, mergedMessage, mergedLegNumbers, openTab]);

  if (isMergingBundles || isLoading || isDepartmentsLoading || isLegStopActionsLoading || !bundleGeneratorBundles) {
    return (
      <div className={baseClassName}>
        <h1>{PAGE_TITLE}</h1>
        <LoadingSpinner className="BundleGenerator--loading" />
        {mergingMessage && <div className="BundleGenerator--message">{mergingMessage}</div>}
      </div>
    );
  }

  return (
    <div className={baseClassName}>
      <h1>{PAGE_TITLE}</h1>
      {!mergedMessage && (
        <input
          type="text"
          className="BundleGenerator--container-number-filter"
          aria-label="Filter by container number"
          placeholder="Filter by container number"
          onChange={(e) => setContainerFilter(e.target.value)}
        />
      )}

      {mergedMessage && <div>{mergedMessage}</div>}

      {!mergedMessage && bundleGeneratorBundles.length === 0 && <div>No dual trans bundle candidates found</div>}

      {!mergedMessage &&
        filteredBundleGeneratorBundles.map((bundle) => (
          <BundleRow
            key={bundle.bundleId}
            bundle={bundle}
            onMergeClick={handleBundleJoinClick}
            onMergeAndPostClick={handleBundleJoinAndPostClick}
            onRejectClick={handleBundleRejectClick}
            isMergingBundles={isMergingBundles}
          />
        ))}
    </div>
  );
}

export default BundleGenerator;
