import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { fetchUserByUuid, selectUsersByUuid } from '@client/_blessed/store/entities';
import moment from 'moment-timezone';
import { BulkHistory } from '../../store/types/BulkHistory.entity';
import { fetchBulkOperationUpdateHistoryDetail, selectBulkOperationUpdatesDetailLoading } from '../../store';
import BulkHistorySubRow from '../BulkHistorySubRow/component';

import './style.css';

interface BulkHistoryRowProps {
  history: BulkHistory;
}

function BulkHistoryRow(props: BulkHistoryRowProps) {
  const baseClassName = 'BulkHistoryRow';
  const appDispatch = useAppDispatch();

  const [showDetail, setShowDetail] = useState(false);

  const isBulkHistoryDetailLoading = useSelector(selectBulkOperationUpdatesDetailLoading(props.history.uuid));
  const usersByUuid = useSelector(selectUsersByUuid);

  useEffect(() => {
    let promise: ReturnType<ReturnType<typeof fetchUserByUuid>> | undefined;

    if (!usersByUuid[props.history.additionalDetail?.userUuid]) {
      promise = appDispatch(fetchUserByUuid(props.history.additionalDetail?.userUuid));
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [props.history]);

  const handleClick = () => {
    if (!showDetail === true && !props.history.items) {
      appDispatch(fetchBulkOperationUpdateHistoryDetail(props.history.uuid));
    }
    setShowDetail(!showDetail);
  };

  const detailSection = useMemo(() => {
    if (isBulkHistoryDetailLoading) {
      return <div className={`${baseClassName}__loading`}>Loading...</div>;
    }

    return props.history.items?.map((item) => (
      <BulkHistorySubRow key={item.uuid} item={item} operationType={props.history.operationType} />
    ));
  }, [isBulkHistoryDetailLoading, props.history.items]);

  const statusClass = useMemo(() => {
    switch (props.history.operationStatus) {
      case 'completed':
        return `${baseClassName}-completed`;
      case 'partially_completed':
        return `${baseClassName}-partailly-completed`;
      case 'failed':
        return `${baseClassName}-failed`;
      case 'running':
        return `${baseClassName}-running`;
      case 'submitted':
      default:
        return `${baseClassName}-submitted`;
    }
  }, [props.history]);

  const userName = useMemo(() => {
    const userUuid = props.history.additionalDetail?.userUuid;

    if (!userUuid) {
      return 'N/A';
    }

    const user = usersByUuid[userUuid];

    if (!user) {
      return userUuid;
    }

    return `${user.firstName} ${user.lastName}`;
  }, [props.history, usersByUuid]);

  return (
    <div>
      <div className={`${baseClassName} ${statusClass}`} onClick={handleClick}>
        <div className={`${baseClassName}-cell`}>{props.history.fileName}</div>
        <div className={`${baseClassName}-cell`}>{props.history.operationStatus}</div>
        <div className={`${baseClassName}-cell`}>
          {moment(props.history.createdAt).tz(moment.tz.guess()).format('MM/DD/YYYY HH:mm:ss z')}
        </div>
        <div className={`${baseClassName}-cell`}>{userName}</div>
      </div>
      {showDetail && detailSection}
    </div>
  );
}

export default BulkHistoryRow;
