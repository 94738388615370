import React, { ChangeEventHandler, MouseEventHandler, ReactElement, useState, useContext } from 'react';
import DropdownSelectInput from '@client/components/DropdownSelectInput';
import { raiseToast } from '@client/components/Toaster/component';
import SystemToast from '@client/components/SystemToast/component';
import TextArea from '@client/components/TextArea';
import Button from '@client/components/Button/component';
import Modal from '@client/components/Modal/component';
import { appActionContext } from '@client/components/App/contexts';
import { bulkCancelOrders } from '@client/state/resources/orders/actions';
import ContainerNumbers from '../ContainerNumbers/component';
import { getCancellableOrdersData } from '../../stores/actions';
import { ShipperOptions, CancellableOrdersData, ContainersNotCancelled, Counts } from '../../stores/models';
import NonCancelledOrders from '../NonCancelledOrders/component';
import './style.css';

interface Props {
  isFetching: boolean;
  shipperOptions: ShipperOptions[];
  count: number;
  cancellationSubmitted: boolean;
  nonCancellableContainers: ContainersNotCancelled[];
  setFetching: (bool: boolean) => void;
  setNonCancelledContainers: (orders: ContainersNotCancelled[]) => void;
  setCounts: (count: Counts) => void;
  submitCancellation: (bool: boolean) => void;
  setErrors: (error: string) => void;
}

interface CancelledOrderPayload {
  id: string;
  attributes: {
    status: string;
  };
}

export default function BulkOrderCancellationForm({
  shipperOptions,
  nonCancellableContainers,
  count,
  cancellationSubmitted,
  setFetching,
  isFetching,
  setNonCancelledContainers,
  setCounts,
  submitCancellation,
  setErrors
}: Props): ReactElement {
  const appDispatch = useContext(appActionContext);

  const [containerNumbers, setContainerNumbersValue] = useState('');
  const [billingAccountUuid, setBillingAccountUuid] = useState('');
  const [cancelledContainers, setCancelledContainers] = useState<string[] | any[]>([]);
  const [isOpen, openModal] = useState(false);
  const [cancellableOrderUuids, setCancellableOrderUuids] = useState<CancelledOrderPayload | any[]>([]);

  const baseClassName = 'bulk-order-delete-form';

  const ontextAreaInput: ChangeEventHandler<HTMLTextAreaElement> = (e): void => {
    const { value } = e.target;
    setContainerNumbersValue(value);
  };

  const onDropdownSelect = (values: ShipperOptions[]): void => {
    const id = values[0] ? values[0]?.uuid : '';
    setBillingAccountUuid(id);
  };

  const reset = () => {
    setContainerNumbersValue('');
    setCancellableOrderUuids([]);
    openModal(false);
  };

  const getCancellableOrderData: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    try {
      setFetching(true);
      submitCancellation(false);

      const queries = { billingAccountUuid, containerNumbers };
      const data = (await getCancellableOrdersData(queries)) as CancellableOrdersData;

      const {
        ordersForCancellation: { cancellableUuids, cancellableContainers },
        containersNotCancelled,
        containersNotCancelledCount,
        ordersCancelCount
      } = data;

      if (ordersCancelCount > 0) {
        setCancellableOrderUuids(cancellableUuids);
        setCancelledContainers(cancellableContainers);
        openModal(true);
      } else {
        setCancelledContainers([]);
        setCancellableOrderUuids([]);
        submitCancellation(true);
      }

      setCounts({ containersNotCancelledCount, ordersCancelCount });
      setNonCancelledContainers(containersNotCancelled);
    } catch (err) {
      const message = 'Unable to make request. Please try again later.';
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={message} />);
      setErrors(message);
      throw err;
    } finally {
      setFetching(false);
    }
  };

  const submitCancellableOrdersPayload: MouseEventHandler<HTMLButtonElement> = async () => {
    try {
      setFetching(true);
      await appDispatch(bulkCancelOrders(cancellableOrderUuids));
      submitCancellation(true);
      reset();
    } finally {
      setFetching(false);
    }
  };

  return (
    <div className={baseClassName}>
      <DropdownSelectInput
        searchBy="label"
        multi={false}
        labelField="label"
        options={shipperOptions}
        valueField="uuid"
        onChange={onDropdownSelect}
      />
      <TextArea
        value={containerNumbers}
        onChange={ontextAreaInput}
        placeholder="Enter container numbers here separate by space or comma"
      />
      <Button
        className={`${baseClassName}__button`}
        theme="2"
        label="Cancel Orders"
        loading={false}
        type="button"
        disabled={!billingAccountUuid || !containerNumbers}
        onClick={getCancellableOrderData}
      />

      {cancelledContainers.length > 0 && cancellationSubmitted && (
        <div>
          <p className={`${baseClassName}__status-text`}>Cancelled containers</p>
          <div>Cancellable counts:{cancelledContainers.length}</div>
          <ContainerNumbers containerNumbers={cancelledContainers} />
        </div>
      )}

      <Modal className={`${baseClassName}__modal`} open={isOpen} disableClose disableParentScroll>
        <div className={`${baseClassName}__modal-content`}>
          <div className={`${baseClassName}__modal-dialogue`}>
            ** Please confirm that you want to delete the following containers. <br />
            Once submitted, operation cannot be changed.
          </div>

          {nonCancellableContainers.length > 0 && (
            <NonCancelledOrders containersNotCancelled={nonCancellableContainers} count={count} />
          )}

          <div className={`${baseClassName}__cancellable-wrapper`}>
            <p className={`${baseClassName}__status-text`}>Cancellable containers</p>
            <div>Cancellation count: {cancelledContainers?.length}</div>
            <ContainerNumbers containerNumbers={cancelledContainers} />
          </div>

          <div className={`${baseClassName}__btn-group`}>
            <Button
              disabled={isFetching}
              className={`${baseClassName}__button`}
              theme="2"
              label="Discard"
              loading={false}
              type="button"
              onClick={() => openModal(false)}
            />
            <Button
              className={`${baseClassName}__button`}
              theme="1"
              label="Confirm Cancellation"
              loading={isFetching}
              type="button"
              onClick={submitCancellableOrdersPayload}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
