import { gracePeriodTypeToUIMappings, importExportUIMappings } from '@client/constants';
import { getDepartmentDisplayName } from '@client/utils/data-processing/department';
import { useSelector } from 'react-redux';
import { selectDepartmentUuidMap } from '@client/_blessed/store/entities';
import ChassisBadge from '@client/components/ChassisBadge/component';
import { BundleGeneratorMatchInfo } from '../../../../../store';

import './style.css';

interface OrderInfoProps {
  matchInfo: BundleGeneratorMatchInfo;
}

function OrderInfo({ matchInfo }: OrderInfoProps) {
  const baseClassName = 'OrderInfo';

  const departments = useSelector(selectDepartmentUuidMap);

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__section`}>
        <div>Order Number: {matchInfo.orderNumber}</div>
        <div>Container: {matchInfo.containerNumber}</div>
        <div>Booking BL: {matchInfo.bookingBL}</div>
        <div>LFD: {matchInfo.lastFreeDay}</div>
      </div>

      <div className={`${baseClassName}__section`}>
        <div>Order Type: {importExportUIMappings[matchInfo.importExport as keyof typeof importExportUIMappings]}</div>
        <div>
          Delivery Type:{' '}
          {gracePeriodTypeToUIMappings[matchInfo.deliveryType as keyof typeof gracePeriodTypeToUIMappings]}
        </div>
        <div>SSL: {getDepartmentDisplayName(departments[matchInfo.steamShippingLineAccountUuid])}</div>
        <div>Per Diem: {matchInfo.perDiemDueDate}</div>
      </div>

      <div className={`${baseClassName}__section`}>
        <div>Container Size: {matchInfo.containerSize}</div>
        <div>Chassis: {matchInfo.chassisNumber ?? 'N/A'}</div>
        <div>
          Chassis Pool:{' '}
          {matchInfo.chassisPool ? (
            <ChassisBadge name={matchInfo.chassisPool.abbreviation} color={matchInfo.chassisPool.badgeColor} />
          ) : (
            'N/A'
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderInfo;
