// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generator-notification-request-list {
  width: 50%;
  height: 100%;
  padding-left: 15px;
}

  .generator-notification-request-list table {
    border: 1px solid var(--color-grayscale-5);
    width: 100%;
    text-align: left;
  }

  .generator-notification-request-list table th {
      font-weight: 500;
      background: var(--color-grayscale-2);
    }

  .generator-notification-request-list table th, .generator-notification-request-list table td {
      padding: 15px 20px;
    }

  .generator-notification-request-list table tr {
      border: 1px solid #ccc;
    }

  .generator-notification-request-list table .r-icon-btn {
      width: 16px;
    }

  .generator-notification-request-list table .r-icon-btn:hover {
        cursor: pointer;
        color: var(--color-red-3);
      }`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BundleGeneratorNotifications/features/GeneratorNotificationRequestList/style.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,kBAAkB;AA6BpB;;EA3BE;IACE,0CAA0C;IAC1C,WAAW;IACX,gBAAgB;EAuBlB;;EArBE;MACE,gBAAgB;MAChB,oCAAoC;IACtC;;EAEA;MACE,kBAAkB;IACpB;;EAEA;MACE,sBAAsB;IACxB;;EAEA;MACE,WAAW;IAMb;;EAJE;QACE,eAAe;QACf,yBAAyB;MAC3B","sourcesContent":[".generator-notification-request-list {\n  width: 50%;\n  height: 100%;\n  padding-left: 15px;\n\n  table {\n    border: 1px solid var(--color-grayscale-5);\n    width: 100%;\n    text-align: left;\n\n    th {\n      font-weight: 500;\n      background: var(--color-grayscale-2);\n    }\n\n    th, td {\n      padding: 15px 20px;\n    }\n\n    tr {\n      border: 1px solid #ccc;\n    }\n\n    .r-icon-btn {\n      width: 16px;\n\n      &:hover {\n        cursor: pointer;\n        color: var(--color-red-3);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
